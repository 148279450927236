<template>
  <div
    class="modal"
    style="z-index: 1005 !important"
    v-bind:class="{ 'is-active': showEditModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Region</p>
        <button class="delete" aria-label="close" @click="toggleClose"></button>
      </header>
      <section class="modal-card-body">
        <template>
          <div class="columns" style="width: 100%">
            <div class="column is-one-third">
              <div
                class="
                  is-size-6 is-flex is-flex-direction-row
                  has-text-weight-semibold
                "
              >
                Name:
              </div>
              <input
                class="input"
                placeholder="Name"
                type="text"
                v-model="regionProps.name"
              />
            </div>
          </div>
        </template>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
          @click="editRegion"
          v-bind:class="isEditing ? editButtonLoading : editButtonNotLoading"
        >
          Save
        </button>
        <button
          class="button is-small level-item is-rounded"
          @click="toggleClose"
        >
          Close
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { regionsCollection } from "@/store/fireStore";
import store from "../../store";

const environment = store.state.environment;

export default {
  data() {
    return {
      environment: store.state.environment,
      isEditing: false,
      editButtonLoading:
        "button is-small is-info level-item is-rounded is-loading",
      editButtonNotLoading: "button is-small is-info level-item is-rounded",
      editSelectedRegion: {
        name: "",
      },
      regionProps: {
        name: "",
      },
    };
  },
  props: {
    showEditModal: {
      type: Boolean,
    },
    selectedRegion: {
      type: Object,
    },
  },
  methods: {
    toggleClose() {
      this.$emit("closeModal");
    },
    toggleRender() {
      this.$emit("renderMap");
    },
    async editRegion() {
      this.isEditing = true;
      const { id } = this.editSelectedRegion;
      const { name: regionName } = this.regionProps;
      try {
        await regionsCollection
          .doc(id)
          .update({
            // ...this.zoneProps,
            id,
            name: regionName,
          })
          .then(() => {
            this.isEditing = false
            this.$emit('renderMap')
            this.toggleClose()
            this.$notify({
              type: 'success',
              group: 'zones',
              title: 'Region Save Successfully'
            })
          })
      } catch (e) {
        this.isEditing = false;
        this.$notify({
          type: "error",
          group: "zones",
          title: "There was an error, please try again",
        });
        console.log(e);
        throw e;
      }
    },
  },
  mounted() {
    console.log("this.selectedRegion:", this.selectedRegion);
    this.editSelectedRegion = Object.assign({}, this.selectedRegion);
    const localStorageAccess = localStorage.getItem("access");
    const { name } = this.selectedRegion;
    this.regionProps = {
      name: name || "",
    };
  },
};
</script>
<style>
</style>
