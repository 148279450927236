var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { "is-active": _vm.showEditModal },
      staticStyle: { "z-index": "1005 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [_vm._v("Edit Region")]),
          _c("button", {
            staticClass: "delete",
            attrs: { "aria-label": "close" },
            on: { click: _vm.toggleClose },
          }),
        ]),
        _c(
          "section",
          { staticClass: "modal-card-body" },
          [
            [
              _c(
                "div",
                { staticClass: "columns", staticStyle: { width: "100%" } },
                [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "is-size-6 is-flex is-flex-direction-row has-text-weight-semibold",
                      },
                      [_vm._v(" Name: ")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regionProps.name,
                          expression: "regionProps.name",
                        },
                      ],
                      staticClass: "input",
                      attrs: { placeholder: "Name", type: "text" },
                      domProps: { value: _vm.regionProps.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.regionProps, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
            ],
          ],
          2
        ),
        _c(
          "footer",
          { staticClass: "modal-card-foot is-justify-content-flex-end" },
          [
            _c(
              "button",
              {
                class: _vm.isEditing
                  ? _vm.editButtonLoading
                  : _vm.editButtonNotLoading,
                on: { click: _vm.editRegion },
              },
              [_vm._v(" Save ")]
            ),
            _c(
              "button",
              {
                staticClass: "button is-small level-item is-rounded",
                on: { click: _vm.toggleClose },
              },
              [_vm._v(" Close ")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }